import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Modal,
  ThemeProvider,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { basicTheme } from "../../themes/customTheme";
import BackDropLoader from "../reusableComponents/BackDropLoader";
import LocationDropDown from "./UserLocation";
import { useDispatch, useSelector } from "react-redux";
import { getEwList } from "../../utils/index";
import { getStatesData } from "../../features/location/locationAPIs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import BasicTextfield from "../reusableComponents/BasicTextfield";
import SnackBars from "../reusableComponents/SnackBar";
import AutoCompleteDropdown from "../reusableComponents/AutoCompleteDropDown";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";
import BasicDropDown from "../reusableComponents/BasicDropDown";
import { getEachPartnerData } from "../../utils";
import { getPartnerData } from "../../features/partner/partnerAPIs";
import { postAppUserData } from "../../features/appUsers/appUsersApi";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const mainGridStyle = {
  display: "flow-root",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  height: "100%",
  bgcolor: "transparent",
  p: 4,
};
const subGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  px: 6,
  boxShadow: 24,
  borderRadius: "1.5rem",
  width: "65%",
  maxHeight: "80%",
  overflowY: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
};
const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "2.2",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
}));

const ButtonStyle = styled(Button)({
  backgroundColor: "#2D6A4F",
  color: "white",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

const CancelButtonStyle = styled(Button)({
  backgroundColor: "white",
  color: "#2D6A4F",
  textTransform: "none",
  borderRadius: "8px",
  fontSize: "16px",
  fontWeight: "600",
  minWidth: "140px",
  maxWidth: "150px",
  height: "54px",
});

export default function AddUser({
  openAddModal,
  handleClose,
  setUserSnackBarMessage = () => {},
  setReload = () => {},
  setOpenSuccessBox = () => {},
  setSuccessMessage = () => {},
  setOpenSuccessBoxType = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [season, setSeason] = useState("");
  const [farmerPartner, setFarmerPartner] = useState(null);
  const [cropName, setCropName] = useState("cotton");
  const [farmingType, setFarmingType] = useState("");
  const [trapInstalled, setTrapsInstalled] = useState("");
  const [farmerType, setFarmerType] = useState("");
  const [farmerName, setFarmerName] = useState("");
  const [farmerNumber, setFarmerNumber] = useState("");
  const [farmerGender, setFarmerGender] = useState("");
  const [birthDate, setBirthDate] = useState(dayjs(new Date()));
  const [farmerAge, setFarmerAge] = useState("");
  const [formattedBirthDate, setFormattedBirthDate] = useState(
    dayjs(new Date()).format("DD/MM/YYYY")
  );
  const [protocol, setProtocol] = useState("");
  const [totalLandHolding, setTotalLandHolding] = useState("");
  const [areaUnderCotton, setAreaUnderCotton] = useState("");
  const [farmerStatus, setFarmerStatus] = useState("");
  const [ewName, setEwName] = useState("");
  const [ewNumber, setEwNumber] = useState("");
  const [ewGender, setEwGender] = useState("");
  const [isEwLead, setIsEwLead] = useState(false);
  const [addUserSnackBar, setAddUserSnackBar] = useState(false);
  const [addUserSnackBarMessage, setAddUserSnackBarMessage] = useState("");
  const [addUserSnackBarType, setAddUserSnackBarType] = useState("success");
  const [ewTotalData, setEwTotalData] = useState([]);
  const [ewList, setEwList] = useState([]);
  const [showEwAdd, setShowEwAdd] = useState(false);
  const [selectedStateOptions, setSelectedStateOptions] = useState(null);
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState(null);
  const [selectedTalukaOptions, setSelectedTalukaOptions] = useState(null);
  const [selectedVillageOptions, setSelectedVillageOptions] = useState(null);
  const [selectedEwOptions, setSelectedEwOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [partnerLocations, setPartnerLocations] = useState([]);
  const [partnerProjects, setPartnerProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [collapseHeader, setCollapseHeader] = useState(false);

  const basicError = {
    farmerName: "",
    farmerNumber: "",
    ewName: "",
    ewPhoneNumber: "",
    areaUnderCotton: "",
    farmerAge:"",
    farmerGender:"",
    farmerStatus:"",
    farmerPartner:"",
  };
  const userStatusList = ["Active", "Inactive"];
  const farmerTypeList = ["Lead", "Cascade"];
  const yesNoList = ["Yes", "No"];
  const genderList = ["Male", "Female", "Transgender", "Prefer not to say"];
  const protocolList = ["Weekly Monitoring Protocol", "CICR"];
  const [partnerDataResponse, setPartnerDataResponse] = useState([]);
  const [statesResponse, setStateResponse] = useState([]);
  const [partnerNamesData, setPartnerNamesData] = useState([]);

  const [errors, setErrors] = useState(basicError);
  const loggedInUser = JSON.parse(window.localStorage.getItem("loggedInUser"));

  const getPartnerList = async () => {
    setLoading(true);
    try {
      const response = await getPartnerData({limit:100000,offset:0});
      if (loggedInUser.details.partner !== null) {
        const partnerId = loggedInUser.details.partner.id;

        // Filter the partner data from response.data["data"]
        const filteredData = response.data["data"].filter(
          (item) => item.id === partnerId
        );
        // Now filteredData contains the partner data if it exists
        setPartnerDataResponse(filteredData);
        const partnerNamesList = filteredData.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setPartnerNamesData(partnerNamesList);
      } else {
        setPartnerDataResponse(response.data["data"]);
        const partnerNamesList = response.data["data"].map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setPartnerNamesData(partnerNamesList);
      }
      setLoading(false);
    } catch (error) {
      setAddUserSnackBarType("error");
      setAddUserSnackBar(true);
      setAddUserSnackBarMessage("Error while getting partner list", error); // Use error.response.data here
      setLoading(false);
    }
  };

  useEffect(() => {
    const params = {};
    const fetchData = async () => {
      try {
        const response = await getStatesData(params);
        if (
          response != null &&
          response != undefined &&
          response.data !== null &&
          response.data !== undefined &&
          response.data.data !== null &&
          response.data.data !== undefined
        ) {
          setStateResponse(response.data.data);
        } else {
          setAddUserSnackBar(true);
          setAddUserSnackBarType("error");
          setAddUserSnackBarMessage("Something went wrong on sever!");
        }
      } catch (error) {
        setAddUserSnackBar(true);
        setAddUserSnackBarType("error");
        setAddUserSnackBarMessage(
          "Not able to fetch state, Please check server!"
        );
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    var newEwList = [];
    ewTotalData.forEach((ew_data) => {
      newEwList.push({
        phone_number: ew_data.phone_number,
        name: ew_data.name,
        id: ew_data.id,
      });
    });

    setEwList(newEwList);
  }, [ewTotalData]);

  useEffect(() => {
    if (farmerPartner !== null && partnerNamesData[0].id !== "") {
      const partnerProjectsTemp = partnerDataResponse
        .filter((partner) => partner.id === farmerPartner.id)
        .flatMap((partner) => partner.projects)
        .map(({ id, name, end_date }) => ({ id, name, end_date }));

      setPartnerProjects(partnerProjectsTemp);
    }
    if (farmerPartner !== null) {
      setSelectedEwOptions(null);
      setSelectedStateOptions(null);
      setSelectedDistrictOptions(null);
      setSelectedTalukaOptions(null);
      setSelectedVillageOptions(null);
      setSelectedProject(null);
    }
  }, [farmerPartner]);
  useEffect(() => {
    setSelectedEwOptions(null);
    setEwTotalData([]);
    if (
      farmerPartner !== null &&
      selectedStateOptions !== null &&
      selectedDistrictOptions !== null
    ) {
      setLoading(true);
      getEwList({
        offset: 0,
        limit: 1000,
        district_ids: [selectedDistrictOptions.id],
        partner_ids:[farmerPartner.id],
      }).then((data) => {
        if (data.data.length === 0) {
          setAddUserSnackBarMessage("No extension worker found");
          setAddUserSnackBarType("error");
          setAddUserSnackBar(true);
        } else {
          setAddUserSnackBar(false);
        }
        setEwTotalData(data.data);
        setLoading(false);
      });
    }
  }, [selectedDistrictOptions]);

  const validateFormData = () => {
    let farmerNameError="";
    //let farmerStatusError="";
    let farmerGenderError="";
    let farmerMobileError="";
    let farmerAgeError="";
    let farmerPartnerNameError="";
    if (farmerName.trim() === "") {
      farmerNameError = "Farmer Name can't be empty"
    } 
    // if (farmerStatus === "") {
    //   farmerStatusError = "Farmer Status can't be empty"
    // }
    if (farmerGender === "") {
      farmerGenderError = "Gender can't be empty"
    }
    if (farmerNumber === "") {
      farmerMobileError = "Phone Number can't be empty"
    }
    if (farmerPartner === null) {
      farmerPartnerNameError = "Partner name can't be empty"
    }
    // if (farmerAge === "") {
    //   farmerAgeError = "Age can't be empty"
    // }
    setErrors({...errors, farmerName: farmerNameError, farmerGender: farmerGenderError, farmerNumber: farmerMobileError, farmerPartner: farmerPartnerNameError, farmerAge: farmerAgeError});
  }

  const handleChangeFarmerGender = (event) => {
    setFarmerGender(event.target.value);
    setErrors({ ...errors, farmerGender: "" });
  };

  const handleChangeEwGender = (event) => {
    setEwGender(event.target.value);
  };

  const handleChangeFarmerStatus = (event) => {
    setFarmerStatus(event.target.value);
    setErrors({ ...errors, farmerStatus: "" });
  };
  const handleChangeFarmerName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX =
      /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
    if (value.trim() === "") {
      setErrors({ ...errors, farmerName: "Farmer Name can't be empty" });
      value = "";
    } else if (!ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      setErrors({
        ...errors,
        farmerName: "Special characters are not allowed in Farmer Name",
      });
      value = "";
    } else {
      setErrors({ ...errors, farmerName: "" });
    }
    setFarmerName(value);
  };

  const handleChangeEwName = (value) => {
    value = value.replace(/^\s+/g, "");
    const ALPHA_NUMERIC_DASH_REGEX =
      /^[a-zA-Z0-9\s]{1,}[\.]{0,1}[A-Za-z0-9\s]{0,}$/;
    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) value = "";
    if (value === "")
      setErrors({
        ...errors,
        ewName: "Extension Worker Name can't be empty ",
      });
    else {
      setErrors({ ...errors, ewName: "" });
    }
    setEwName(value);
  };

  const handleChangeFarmerPartner = (event) => {
    if (event.target.value !== (null || undefined)) {
      // setFarmerPartner(event.target.value);
      setSelectedEwOptions(null);
      setSelectedStateOptions(null);
      setSelectedDistrictOptions(null);
      setSelectedTalukaOptions(null);
      setSelectedVillageOptions(null);
    }
  };

  const onDateChange = (newValue) => {
    var formattedDate = dayjs(newValue).format("DD/MM/YYYY");
    setFormattedBirthDate(formattedDate);
    setBirthDate(newValue);
  };

  const handleChangeFarmerNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    const basicReg = /^[0-9]*$/;
    const reg = /^[1-9][0-9]*$/;
    if (!basicReg.test(phoneNumber)) {
      setErrors({
        ...errors,
        farmerNumber: "Invalid phone number",
      });
    }
    if (phoneNumber === "") {
      setErrors({ ...errors, farmerNumber: "Phone number can't be empty" });
    } else if (!reg.test(phoneNumber)) {
      setErrors({
        ...errors,
        farmerNumber: "Phone number should not start with 0",
      });
    } else if (phoneNumber.length !== 10) {
      setErrors({ ...errors, farmerNumber: "Phone number should be 10 digits" });
    } else {
      setErrors({ ...errors, farmerNumber: "" });
    }
    setFarmerNumber(phoneNumber);
  };

  const handleChangeFarmerAge = (age) => {
  age = age.trim();
  const basicReg = /^[0-9]*$/;
  const reg = /^[1-9][0-9]*$/;
  
  if (!basicReg.test(age)) {
    setErrors({
      ...errors,
      farmerAge: "Invalid age",
    });
  }
  if (age === "") {
    setErrors({ ...errors, farmerAge: "" });
  } else if (!reg.test(age)) {
    setErrors({
      ...errors,
      farmerAge: "Farmer's Age should not start with 0",
    });
  } else if (parseInt(age, 10) === 0) {
    setErrors({ ...errors, farmerAge: "Farmer's Age can't be zero" });
  } else {
    setErrors({ ...errors, farmerAge: "" });
  }
  setFarmerAge(age);
  };

  const handleChangeEwNumber = (phoneNumber) => {
    phoneNumber = phoneNumber.trim();
    const reg = /^[1-9][0-9]*$/;
    if (phoneNumber === "") {
      setErrors({ ...errors, ewPhoneNumber: "Phone number can't be empty " });
    } else if (!reg.test(phoneNumber)) {
      setErrors({
        ...errors,
        ewPhoneNumber: "Phone number should not start with 0 ",
      });
    } else if (phoneNumber.length !== 10) {
      setErrors({
        ...errors,
        ewPhoneNumber: "Phone number should be 10 digits",
      });
    } else {
      setErrors({ ...errors, ewPhoneNumber: "" });
    }
    setEwNumber(phoneNumber);
  };

  const handleCloseByClearIcon = () => {
    setErrors(basicError);
    setSeason("");
    setCropName("");
    setFarmerPartner(null);
    setFarmingType("");
    setTrapsInstalled("");
    setFarmerName("");
    setFarmerNumber("");
    setFarmerGender("");
    setProtocol("");
    setTotalLandHolding("");
    setAreaUnderCotton("");
    //setFarmerStatus("");
    setEwGender("");
    setEwName("");
    setEwNumber("");
    setEwList([]);
    setEwTotalData([]);
    setIsEwLead(false);
    setAddUserSnackBar(false);
    setAddUserSnackBarMessage("");
    setShowEwAdd(false);
    setSelectedStateOptions(null);
    setSelectedDistrictOptions(null);
    setSelectedTalukaOptions(null);
    setSelectedVillageOptions(null);
    setSelectedEwOptions(null);
    setPartnerLocations(null);
    setBirthDate(dayjs(new Date()));
    handleClose();
  };

  const setLocationForApi = () => {
    var locations = {
      state_ids: [],
      district_ids: [],
      taluka_ids: [],
      village_ids: [],
    };
    if (selectedStateOptions !== null) {
      locations["state_ids"].push(selectedStateOptions.id);
    }
    if (selectedDistrictOptions !== null) {
      locations["district_ids"].push(selectedDistrictOptions.id);
    }
    if (selectedTalukaOptions !== null) {
      locations["taluka_ids"].push(selectedTalukaOptions.id);
    }
    if (selectedVillageOptions !== null) {
      locations["village_ids"].push(selectedVillageOptions.id);
    }

    return locations;
  };
  useEffect(() => {
    if (!showEwAdd && selectedEwOptions !== null) {
      setEwName(selectedEwOptions.name);
      setEwNumber(selectedEwOptions.phone_number);
    } else if (!showEwAdd && selectedEwOptions === null) {
      setEwName("");
      setEwNumber("");
    }
  }, [selectedEwOptions]);

  const handleChangeAddEw = (value) => {
    setShowEwAdd(value);
    setSelectedEwOptions(null);
    setEwName("");
    setEwNumber("");
    setEwGender("");
  };

  const handleSaveData = async () => {
    var locations = setLocationForApi();
    if (
      errors.farmerName === "" &&
      errors.farmerNumber === "" &&
      errors.ewName === "" &&
      errors.ewPhoneNumber === "" &&
      errors.farmerAge === "" &&
      // farmerAge!=="" && 
      farmerName !== "" &&
      farmerNumber !== "" &&
      //farmerStatus !== "" &&
      farmerPartner !== null &&
      // formattedBirthDate !== "" &&
      ewName !== "" &&
      ewNumber !== "" &&
      selectedProject !== null &&
      locations.village_ids.length > 0
    ) {
      setAddUserSnackBar(false);
      setAddUserSnackBarType("success");
      setAddUserSnackBarMessage("");
      //var isActive = farmerStatus === "Active" ? true : false;

      var params = {
        farmer_name: farmerName,
        farmer_phone_number: farmerNumber,
        farmer_gender: farmerGender,
        farmer_dob: formattedBirthDate,
        partner_id: farmerPartner.id,
        ew_name: ewName,
        ew_phone_number: ewNumber,
        ew_gender: ewGender,
        project_id: selectedProject.id,
        farmer_location_ids: locations.village_ids,
        ew_location_ids: locations.district_ids,
        //is_active: isActive,
        // farmer_age:farmerAge
      };
      params["farmer_age"] = (farmerAge==="" ? null:farmerAge)
      setLoading(true);
      try {
        setLoading(true);
        const response = await postAppUserData(params);
        setReload(true);
        setLoading(false);
        handleClose();
        setOpenSuccessBox(true);
        setSuccessMessage(response.data["message"]);
        setOpenSuccessBoxType('success');
      } catch (error) {
        setOpenSuccessBox(true);
        setSuccessMessage(error.response.data["message"]);
        setOpenSuccessBoxType('error');
        setLoading(false);
        console.error("API Error:", error.response.data["message"]); // Log the error message
      }
    } else {
      validateFormData();
      setOpenSuccessBox(true);
      setSuccessMessage("Please fill all the required fields");
      setOpenSuccessBoxType('error');
    }
  };

  const projectOptionDisabledFunc = (option) => {
    const endDate = new Date(option?.end_date);
    endDate.setHours(23, 59, 59, 0);
    const currentDate = new Date();
    return endDate <= currentDate;
  }

  return (
    <Modal open={openAddModal}>
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Grid
            container
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              paddingTop: 4,
            }}
          >
            <Grid item xs={7}>
              <Typography variant="h4" style={{ marginBottom: 10 }}>
                Add Farmer
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: "end"}}>
              <Box sx={{ display: "flex", alignItems: "start", cursor: "pointer" }} onClick={() => setCollapseHeader(prev => !prev)}>
                <Typography variant="h5" >
                  {collapseHeader ? "Farmer Details" : "Collapse"}
                </Typography>
                {collapseHeader ? <KeyboardArrowUpIcon sx={{ fontSize: "2rem" }}/> : <KeyboardArrowDownIcon sx={{ fontSize: "2rem" }}/>}
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CloseRoundedIcon
                className={classes.closeBtnStyle}
                onClick={handleCloseByClearIcon}
              />
            </Grid>
            <Grid
              container
              item
              spacing={2}
              xs={12}
              sx={{
                width: "90%",
                p: 1
                // margin: "auto"
              }}
            >
              {!collapseHeader && <><Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>Farmer Name</Typography>
                <BasicTextfield
                  label="Enter Farmer Name"
                  is_phone={false}
                  handleChange={handleChangeFarmerName}
                  is_disabled={false}
                  index={1}
                  error={errors.farmerName !== ""}
                  helperText={errors.farmerName}
                  required={true}
                  value={farmerName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>
                  Farmer Mobile Number
                </Typography>
                <BasicTextfield
                  label="Enter Farmer Mobile Number"
                  is_phone={true}
                  handleChange={handleChangeFarmerNumber}
                  is_disabled={false}
                  index={2}
                  error={errors.farmerNumber !== ""}
                  helperText={errors.farmerNumber}
                  required={true}
                  value={farmerNumber}
                  number={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>Farmer Gender</Typography>
                <BasicDropDown
                  text="Select Farmer Gender"
                  value={farmerGender}
                  handleChange={handleChangeFarmerGender}
                  menuList={genderList}
                  error={errors.farmerGender !== ""}
                  errorText={errors.farmerGender}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>Farmer Age</Typography>
                <BasicTextfield
                  label="Enter Farmer Age"
                  handleChange={handleChangeFarmerAge}
                  error={errors.farmerAge !== ""}
                  helperText={errors.farmerAge}
                  // required={true}
                  value={farmerAge}
                  number={true}
                />
              </Grid></>}
              {/* <Grid item xs={12} sm={6}>
                <Typography sx={{ marginBottom: 1 }}>Farmer Status</Typography>
                <BasicDropDown
                  text="Select Farmer Status"
                  value={farmerStatus}
                  handleChange={handleChangeFarmerStatus}
                  menuList={userStatusList}
                  required={true}
                  error={errors.farmerStatus !== ""}
                  errorText={errors.farmerStatus}
                />
              </Grid> */}

              <Grid item xs={12} sm={6}>
                <Typography
                  sx={{
                    marginBottom: 1,
                  }}
                >
                  Partner Name
                </Typography>
                <AutoCompleteDropdown
                  width="auto"
                  menuList={partnerNamesData}
                  selectedOptions={farmerPartner}
                  setSelectedOptions={setFarmerPartner}
                  label="Select Partner"
                  required={true}
                  onOpen={getPartnerList}
                  loading={loading}
                  error={errors.farmerPartner !== ""}
                  errorText={errors.farmerPartner}
                />
              </Grid>
              {farmerPartner !== null && (
                <React.Fragment>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{
                        marginBottom: 1,
                      }}
                    >
                      Project Name
                    </Typography>
                    <AutoCompleteDropdown
                      width="auto"
                      menuList={partnerProjects}
                      selectedOptions={selectedProject}
                      setSelectedOptions={setSelectedProject}
                      optionDisabledFunc={projectOptionDisabledFunc}
                      label="Select Project"
                      required={true}
                    />
                  </Grid>
                </React.Fragment>)}
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            sx={{
              width: "90%",
            }}
          >
            {farmerPartner !== null && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Typography>Location </Typography>
                </Grid>
                <LocationDropDown
                  stateList={statesResponse}
                  partnerLocations={partnerLocations}
                  selectedStateOptions={selectedStateOptions}
                  setSelectedStateOptions={setSelectedStateOptions}
                  selectedDistrictOptions={selectedDistrictOptions}
                  setSelectedDistrictOptions={setSelectedDistrictOptions}
                  selectedTalukaOptions={selectedTalukaOptions}
                  setSelectedTalukaOptions={setSelectedTalukaOptions}
                  selectedVillageOptions={selectedVillageOptions}
                  setSelectedVillageOptions={setSelectedVillageOptions}
                  farmerPartner={farmerPartner}
                />
                <Grid item xs={12} sm={6}>
                  <Typography
                    sx={{
                      marginBottom: 1,
                      color:
                        showEwAdd || ewList.length === 0
                          ? "#ACADAD"
                          : "#444444",
                    }}
                  >
                    Extension Worker
                  </Typography>
                  <AutoCompleteDropdown
                    width="auto"
                    menuList={ewList}
                    selectedOptions={selectedEwOptions}
                    setSelectedOptions={setSelectedEwOptions}
                    nameNumber={true}
                    disable={showEwAdd || ewList.length === 0}
                    label="Select Extension Worker"
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    sx={{
                      color: "#2D6A4F",
                      marginTop: 5,
                      "&.Mui-checked": { color: "#2D6A4F" },
                    }}
                    label="Add Extension Worker"
                    control={
                      !showEwAdd ? (
                        <Checkbox checked={false} />
                      ) : (
                        <Checkbox checked={true} />
                      )
                    }
                    onChange={(e) => handleChangeAddEw(e.target.checked)}
                  />
                </Grid>
                {showEwAdd && (
                  <React.Fragment>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ marginBottom: 1 }}>
                        Extension Worker Name
                      </Typography>
                      <BasicTextfield
                        label="Enter Extension Worker Name"
                        is_phone={false}
                        handleChange={handleChangeEwName}
                        is_disabled={false}
                        index={4}
                        error={errors.ewName !== ""}
                        helperText={errors.ewName}
                        required={true}
                        value={ewName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ marginBottom: 1 }}>
                        Extension Worker Mobile Number
                      </Typography>
                      <BasicTextfield
                        label="Enter Extension Worker Mobile Number"
                        is_phone={true}
                        handleChange={handleChangeEwNumber}
                        is_disabled={false}
                        index={5}
                        error={errors.ewPhoneNumber !== ""}
                        helperText={errors.ewPhoneNumber}
                        required={true}
                        value={ewNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ marginBottom: 1 }}>
                        Extension Worker Gender
                      </Typography>
                      <BasicDropDown
                        text="Select Extension Worker Gender"
                        value={ewGender}
                        handleChange={handleChangeEwGender}
                        menuList={genderList}
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Grid>
          <Grid
            container
            item
            sm={12}
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 1,
              backgroundColor: "white",
              justifyContent: "center",
              display: "flex", // Add display:flex to create a flex container
            }}
          >
            <Grid item sx={{ margin: "30px" }}>
              <CancelButtonStyle
                variant="contained"
                name="cancel"
                onClick={handleClose}
              >
                Cancel
              </CancelButtonStyle>
            </Grid>
            <Grid item sx={{ margin: "30px" }}>
              <ButtonStyle
                variant="contained"
                name="add"
                startIcon={<CheckIcon size="large" />}
                onClick={handleSaveData}
              >
                Save
              </ButtonStyle>
            </Grid>
            {addUserSnackBar && (
              <SnackBars
                open={addUserSnackBar}
                handleClose={() => setAddUserSnackBar(false)}
                severity={addUserSnackBarType}
                message={addUserSnackBarMessage}
                autoHideDuration={6000}
                vertical="bottom"
                horizontal="center"
                style={{ willChange: "transform" }}
              />
            )}
          </Grid>
          <BackDropLoader loading={loading} />
        </ThemeProvider>
      </Grid>
    </Modal>
  );
}
