import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, ThemeProvider, Box } from "@mui/material";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import { basicTheme } from "../../themes/customTheme";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import AddVillageModal from "../../components/location/AddVillageModal";
import { CSVLink } from "react-csv";
import SnackBars from "../../components/reusableComponents/SnackBar";
import EditLocationModal from "../../components/location/EditLocationModal";
import DeleteLocation from "../../components/location/deleteLocationModal";
import FilterPopOver from "../../components/location/FilterPopOver";
import useMediaQuery from "@mui/material/useMediaQuery";
import AlertDialog from "../../components/reusableComponents/AlertDialog";
import LinearProgress from "@mui/material/LinearProgress";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getLocationData, getStatesData } from "./locationAPIs";
import SuccessErrorDialogBox from "../../components/reusableComponents/SuccessErrorDialogBox";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

const initialHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sort: false,
    sticky:true
  },
  {
    key: "state",
    label: "State",
    type: "text",
    sort: true,
    capital:true
  },
  {
    key: "district",
    label: "District",
    type: "text",
    sort: true,
    capital:true
  },
  {
    key: "taluka",
    label: "Taluka",
    type: "text",
    sort: true,
    capital:true
  },
  {
    key: "village",
    label: "Village",
    type: "text",
    sort: true,
    capital:true
  },
  // {
  //   key: "is_active",
  //   label: "Status",
  //   type: "boolean",
  //   sort: true,
  // },
];

export default function Location() {
  const dispatch = useDispatch();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [openAddModal, setopenAddModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [sortingType, setSortingType] = useState("asc");
  const [sortBy, setSortBy] = useState("state");
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [snackBarType, setSnackBarType] = useState("success");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [page, setPage] = React.useState(0);
  const [filterApiCall, setFilterApiCall] = useState(false);
  const [locationIds, setLocationIds] = useState([]);
  const [locationDataCount, setLocationDataCount] = useState(-1);
  const [locationData, setLocationData] = useState([]);
  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [selectedStatesOptions, setSelectedStatesOptions] = useState([]);
  const [selectedDistrictOptions, setSelectedDistrictOptions] = useState([]);
  const [selectedTalukaOptions, setSelectedTalukaOptions] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiCall, setApiCall] = useState(false);
  const [getStatesResponse, setGetStateResponse] = useState([{
    id: "",
    name: "",
  },]);
  const [openSuccessBox, setOpenSuccessBox] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
    setApplyFilter(false);
    setClearFilter(false);
  };

  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
  };

  const rowsPerPage = 10;

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  useEffect(() => {
    if (applyFilter === true) {
      var tempLocationIds = []
      if(selectedStatesOptions.length > 0) {
        selectedStatesOptions.forEach((obj)=>{
          tempLocationIds.push(obj.id)
        })
      }
      if(selectedDistrictOptions.length > 0) {
        selectedDistrictOptions.forEach((obj)=>{
          tempLocationIds.push(obj.id)
        })
      }
      if(selectedTalukaOptions.length > 0) {
        selectedTalukaOptions.forEach((obj)=>{
          tempLocationIds.push(obj.id)
        })
      }
      setLocationIds(tempLocationIds)
    }
  }, [applyFilter]);

  useEffect(()=>{
    setPage(0);
    setOffset(0);
  },[searchBarData])

  useEffect(() => {
    if (clearFilter === true) {
      setLocationIds([]);
      setSelectedStatesOptions([]);
      setSelectedDistrictOptions([]);
      setSelectedTalukaOptions([]);
      setPage(0);
      setOffset(0);
      setApiCall(true);
    }
  }, [clearFilter]);

  useEffect(() => {
    if (snackBarMessage !== "" && snackBarMessage !==undefined) setShowSnackBar(true);
  }, [snackBarMessage]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
    }
  }, [applyFilter])

  // useEffect(() => {
  //   if (exportData){
  //     setLoading(true)
  //     dispatch(
  //       getExportedData({
  //         sort_by: sortingType,
  //         sort_type: sortBy,
  //         location_ids: locationIds,
  //         search: searchBarData,
  //       })
  //     ).then((res) => {
  //       setLoading(false)
  //       setExportData(false);
  //       if(res.error!==undefined){
  //         setSnackBarMessage("Something went wrong!")
  //         setSnackBarType("error")
  //         setShowSnackBar(true)
  //       }
  //       else{
  //         setExportedFileName(
  //           "location_data" + ".csv"
  //         );
  //         csvLink.current.link.click();
  //       }
  //     });
  //   }
  // }, [exportData]);
  useEffect(() => {
    setApiCall(true);
  }, [
    searchBarData,
    offset,
    sortingType,
    sortBy,
    applyFilter,
  ]);
  // useEffect(() => {
  //   if (locationIds.length !== 0 || searchBarData !== "") {
  //     setFilterApiCall(true);
  //   }
  // }, [searchBarData, applyFilter, offset, locationIds, sortingType, sortBy, reload]);

  useEffect(() => {
    if (reload || apiCall) {
      setLoading(true)
      const params = {
        offset: offset,
        limit: rowsPerPage,
        sort_key: sortBy,
        sort_by: sortingType,
      }
      if(locationIds.length !== 0){
        params['location_ids'] = locationIds
      }
      if(searchBarData !== ""){
        params["search"] = searchBarData;
      }
      const fetchData = async () => {
        try {
          const response = await getLocationData(params)
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setLocationDataCount(response.data.count)
            setLocationData(response.data.data);
          } else {
            setShowSnackBar(true);
            setSnackBarType("error");
            setSnackBarMessage("Something went wrong on sever!");
          }
        } catch (error) {
          setShowSnackBar(true);
          setSnackBarType("error");
          setSnackBarMessage(error.message);
          console.error("API Error:", error);
        } finally {
          setLoading(false);
          setApiCall(false);
          setReload(false);
        }
      };
      fetchData();
    }
  }, [dispatch, apiCall, reload]);

  // useEffect(() => {
  //   if (filterApiCall) {
  //     setLoading(true)
  //     const params = {
  //       offset: offset,
  //       limit: rowsPerPage,
  //       sort_type: sortBy,
  //       sort_by: sortingType,
  //       location_ids: locationIds,
  //       search: searchBarData,
  //     }
  //     const fetchData = async () => {
  //       try {
  //         const response = await getLocationData(params, true)
  //         if (response != null && response != undefined &&
  //           response.data !== null && response.data !== undefined &&
  //           response.data.data !== null && response.data.data !== undefined
  //         ) {
  //           setLocationDataCount(response.data.count)
  //           setLocationData(response.data.data);
  //         } else {
  //           setShowSnackBar(true);
  //           setSnackBarType("error");
  //           setSnackBarMessage("Something went wrong on sever!");
  //         }
  //       } catch (error) {
  //         setShowSnackBar(true);
  //         setSnackBarType("error");
  //         setSnackBarMessage(error.message);
  //         console.error("API Error:", error);
  //       } finally {
  //         setLoading(false);
  //         setFilterApiCall(false);
  //         setReload(false);
  //       }
  //     };
  //     fetchData();
  //   }
  // }, [filterApiCall]);

  useEffect(() => {
    if (getStatesResponse[0].id === "" && openFilterPopOver !== null) {
      const params = {}
      const fetchData = async () => {
        try {
          const response = await getStatesData(params)
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setGetStateResponse(response.data.data);
          } else {
            // setShowSnackBar(true);
            // setSnackBarType("error");
            // setSnackBarMessage("Something went wrong on sever!");
          }
        } catch (error) {
          // setShowSnackBar(true);
          // setSnackBarType("error");
          // setSnackBarMessage("Not able to fetch state, Please check server");
        }
      };
      fetchData();
    }
  }, [openFilterPopOver]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          {!isSmallScreen && (
            <TopFilterBar
              addtext="Add Village"
              setAddOpen={setopenAddModal}
              applyFilter={applyFilter}
              setApplyFilter={setApplyFilter}
              searchBarData={searchBarData}
              setSearchBarData={setSearchBarData}
              searchBarText={searchBarText}
              setSeachBarText={setSeachBarText}
              setExportData={setExportData}
              handleShowFilterPopOver={handleShowFilterPopOver}
              searchBy="Search By Location"
              loading={loading}
              showFilter={true}
              filterSelected = {selectedStatesOptions.length!==0}
            />
          )}
          {openFilterPopOver && !isSmallScreen && (
            <FilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              // setFilterBy={setFilterBy}
              // setFilterIds={setFilterIds}
              setApplyFilter={setApplyFilter}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
              selectedStatesOptions={selectedStatesOptions}
              setSelectedStatesOptions={setSelectedStatesOptions}
              selectedDistrictOptions={selectedDistrictOptions}
              setSelectedDistrictOptions={setSelectedDistrictOptions}
              selectedTalukaOptions = {selectedTalukaOptions}
              setSelectedTalukaOptions={setSelectedTalukaOptions}
              getStatesResponse={getStatesResponse}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {!loading &&(
            <DesktopTable
              key={locationData}
              headCells={initialHeadCells}
              tableResponse={locationData}
              totalCount={locationDataCount}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setopenEditModal={setopenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
            />
          )}
        </Grid>
        {openAddModal && (
          <AddVillageModal
            openAddModal={openAddModal}
            handleClose={() => setopenAddModal(false)}
            setSnackBarMessage={setSnackBarMessage}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
          />
        )}
        {openSuccessBox && 
        <SuccessErrorDialogBox open={openSuccessBox}
        handleClose={() => setOpenSuccessBox(false)}
        text={successMessage}
        />
        }
        <CSVLink
          data={locationData}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
        {openEditModal && (
          <EditLocationModal
            openModal={openEditModal}
            handleClose={() => setopenEditModal(false)}
            data={editData}
            setSnackBarMessage={setSnackBarMessage}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
          />
        )}

        {openAlertModal && (
          <AlertDialog
            handleClose={() => setOpenAlertModal(false)}
            open={openAlertModal}
            heading="Can't delete this location "
            text="Partners or Users are mapped with this location.Please unmap them and then
            try deleting again!"
          />
        )}

        {openDeleteModal && (
          <DeleteLocation
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteData.village}
            setSnackBarMessage={setSnackBarMessage}
            setReload={setReload}
            setOpenAlertModal={setOpenAlertModal}
          />
        )}
        {showSnackBar && (
          <SnackBars
            open={showSnackBar}
            handleClose={() => {setShowSnackBar(false);setSnackBarMessage("");}}
            severity={snackBarType}
            message={snackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position:"absolute",
              zIndex: (theme) => theme.zIndex.drawer +1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
