import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import StackTable from "../../components/reusableComponents/StackTable";
import PartnerFilterPopOver from "../../components/projects/partnerFilterPopOver";
import SnackBars from "../../components/reusableComponents/SnackBar";
import AlertDialog from "../../components/reusableComponents/AlertDialog";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AddFormModal from "../../components/formManagement/addFormModal";
import QuestionsListModal from "../../components/formManagement/questionsListModal";
import EditFormModal from "../../components/formManagement/editFormModal";
import DeleteForm from "../../components/formManagement/deleteForm";
import { getFromData } from "./formManagementAPIs";
import SuccessErrorDialogBox from "../../components/reusableComponents/SuccessErrorDialogBox";

const MainGrid = styled(Grid)({
  // flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

const initialHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sort: false,
    sticky: true,
  },
  {
    key: "form",
    label: "Form",
    type: "text",
    sort: true,
    capital: true,
  },
  {
    key: "project",
    label: "Project",
    type: "text",
  },
  // {
  //   key: "is_active",
  //   label: "Status",
  //   type: "boolean",
  //   sort: true,
  // },
  {
    key: "questions",
    label: "Questions",
    type: "question",
  },
  {
    key: "is_translated",
    label: "Upload Status",
    type: "translated_status",
  },
  {
    key: "icon",
    label: "Form Category",
    type: "text",
  },
];

const languages = [
  "english",
  "hindi",
  // "punjabi",
  "marathi",
  "gujarati",
  // "tamil",
  // "telugu",
  // "kannada",
  // "odia",
];

export default function FormManagement({}) {
  const dispatch = useDispatch();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowsPerPage = 10;
  const [openAddModal, setopenAddModal] = React.useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [formSnackBarType, setFormSnackBarType] = useState("success");
  const [formSnackBarMessage, setFormSnackBarMessage] = useState("");
  const [showFormSnackBar, setFormShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [openListModal, setOpenListModal] = React.useState(false);
  const [listData, setListData] = React.useState([]);
  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formDataCount, setFormDataCount] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [openSuccessBox, setOpenSuccessBox] = useState(false);
  const [openSuccessBoxType,setOpenSuccessBoxType] = useState("success")
  const [successMessage, setSuccessMessage] = useState("");

  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
  };

  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
    setApplyFilter(false);
    setClearFilter(false);
  };

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );
  useEffect(() => {
    if (clearFilter) {
      setPage(0);
      setOffset(0);
    }
  }, [clearFilter]);

  useEffect(() => {
    setPage(0);
    setOffset(0);
  }, [searchBarData]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
    }
  }, [applyFilter]);

  useEffect(() => {
    setApiCall(true);
  }, [searchBarData, offset, sortingType, sortBy, filters]);

  useEffect(() => {
    if (apiCall || reload) {
      setLoading(true);
      const params = {
        filters: JSON.stringify(filters),
        search: searchBarData,
        offset: offset,
        limit: rowsPerPage,
        sort_key: sortBy == "form" ? "name" : sortBy,
        sort_by: sortingType,
      };

      const fetchData = async () => {
        try {
          const response = await getFromData(params);
          if (
            response != null &&
            response != undefined &&
            response.data !== null &&
            response.data !== undefined &&
            response.data.data !== null &&
            response.data.data !== undefined
          ) {
            setFormDataCount(response.data.count);
            setFormData(response.data.data);
          } else {
            setFormSnackBarMessage("Something went wrong on sever!");
            setFormSnackBarType("error");
            setFormShowSnackBar(true);
          }
        } catch (error) {
          setFormSnackBarType("error");
          setFormSnackBarMessage(error.message);
          setFormShowSnackBar(true);
          console.error("API Error:", error);
        } finally {
          setLoading(false);
          setApiCall(false);
          setReload(false);
        }
      };

      fetchData();
    }
  }, [dispatch, apiCall, reload]);

  useEffect(() => {
    if (formSnackBarMessage !== "" && formSnackBarMessage !== undefined)
      setFormShowSnackBar(true);
  }, [formSnackBarMessage]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          <TopFilterBar
            addtext="Add Form"
            setAddOpen={setopenAddModal}
            searchBarData={searchBarData}
            setSearchBarData={setSearchBarData}
            searchBarText={searchBarText}
            setSeachBarText={setSeachBarText}
            handleShowFilterPopOver={handleShowFilterPopOver}
            setExportData={setExportData}
            searchBy="Search by form name"
            isExport={false}
            loading={loading}
          />
          {/* {openFilterPopOver && (
            <PartnerFilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              setApplyFilter={setApplyFilter}
              filters={filters}
              setFilters={setFilters}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
            />
          )} */}
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {isSmallScreen ? (
            <StackTable
              key={formData}
              headCells={initialHeadCells}
              tableResponse={formData}
              totalCount={formDataCount}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setopenEditModal={setopenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
              setOpenListModal={setOpenListModal}
              setListData={setListData}
            />
          ) : (
            !loading && (
              <DesktopTable
                key={formData}
                headCells={initialHeadCells}
                tableResponse={formData}
                totalCount={formDataCount}
                setOffset={setOffset}
                rowsPerPage={rowsPerPage}
                setSortingType={setSortingType}
                sortingType={sortingType}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setopenEditModal={setopenEditModal}
                setEditData={setEditData}
                setOpenDeleteModal={setOpenDeleteModal}
                setDeleteData={setDeleteData}
                setPage={setPage}
                page={page}
                openEditModal={openEditModal}
                openDeleteModal={openDeleteModal}
                setOpenListModal={setOpenListModal}
                setListData={setListData}
                setReload={setReload}
              />
            )
          )}
        </Grid>
        {openListModal && (
          <QuestionsListModal
            openListModal={openListModal}
            handleClose={() => setOpenListModal(false)}
            questions={listData}
            languages={languages}
          />
        )}
        {openAddModal && (
          <AddFormModal
            openAddModal={openAddModal}
            handleClose={() => setopenAddModal(false)}
            setFormSnackBarMessage={setFormSnackBarMessage}
            setFormSnackBarType={setFormSnackBarType}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
            languages={languages}
            setOpenSuccessBoxType={setOpenSuccessBoxType}
          />
        )}
        {openSuccessBox && (
          <SuccessErrorDialogBox
            open={openSuccessBox}
            handleClose={() => setOpenSuccessBox(false)}
            text={successMessage}
            type={openSuccessBoxType}
          />
        )}
        {openEditModal && (
          <EditFormModal
            openEditModal={openEditModal}
            handleClose={() => setopenEditModal(false)}
            data={editData}
            setFormSnackBarType={setFormSnackBarType}
            setFormSnackBarMessage={setFormSnackBarMessage}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
            languages={languages}
            setOpenSuccessBoxType={setOpenSuccessBoxType}
          />
        )}
        {openDeleteModal && (
          <DeleteForm
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteData}
            setSnackBarType={setFormSnackBarType}
            setSnackBarMessage={setFormSnackBarMessage}
            setReload={setReload}
          />
        )}
        <CSVLink
          data={formData}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
          separator={";"}
        />
        {showFormSnackBar && (
          <SnackBars
            open={showFormSnackBar}
            handleClose={() => {
              setFormShowSnackBar(false);
              setFormSnackBarMessage("");
            }}
            severity={formSnackBarType}
            message={formSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
