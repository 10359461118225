import React, { useState } from "react";
import { Grid, Typography, Modal, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import { makeStyles } from "@mui/styles";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FormCard from "../formManagement/formCard"
import EditFormCard from "../formManagement/editFormCard";

const useStyles = makeStyles(() => ({
    closeBtnStyle: {
        zoom: "2.2",
        alignItems: "right",
        color: "#444444",
        cursor: 'pointer', '&:hover': { opacity: 0.7 },
        maxHeight: "1rem",
        maxWidth: "1rem"
    }
}));
const subGridStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    px: 4,
    borderRadius: "1.5rem",
    width: "65%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingLeft: 5,
    paddingTop: 1
};

export default function FormListModal({
    openListModal,
    handleClose = () => { },
    forms
}) {
    const classes = useStyles();
    const handleCloseByClearIcon = () => {
        handleClose();
    };

    const [selectedFormIndex, setSelectedFormIndex] = useState(0);

    function reverseTransformQuestionsData(formattedData) {
        const languages = ["english", "hindi", "gujarati", "marathi"];
        return formattedData.map((item) => {
          const currentDataItem = {
            response_type: item.response_type,
          };
      
          languages.forEach((language) => {
            if (item[language]) {
              currentDataItem[language] = item[language];
            }
          });
      
          if (item.model_metadata) {
            const { question_type, units, values } = item.model_metadata;
      
            if (question_type) {
              currentDataItem.question_type = question_type;
            }
      
            if (units) {
              const unitList = Object.fromEntries(
                languages.map((languageKey) => [
                  languageKey,
                  units[languageKey] || [],
                ])
              );
              currentDataItem.units = unitList;
            }
      
            if (values) {
              if (question_type === "MCQ") {
      
                const dict = {};
                languages.forEach(language => {
                  if (values[language]) {
                    dict[language] = {};
      
                    values[language].forEach(item => {
                      dict[language][item.master] = item.slave;
                    });
                  } else {
                    dict[language] = {};
                  }
                });
                currentDataItem.dict = dict;
      
                const mcqresponses = {};
                languages.forEach((language) => {
                  if (values[language]) {
                    mcqresponses[language] = values[language].map((item) => item.master);
                  } else {
                    mcqresponses[language] = []
                  }
                });
                currentDataItem.responses = mcqresponses;
      
              }
            }
          }
      
          if (item.responses) {
            currentDataItem.responses = item.responses;
          } else if (!(item.response_type === "audio" &&
            item.model_metadata &&
            item.model_metadata.question_type &&
            item.model_metadata.question_type === "MCQ")) {
            currentDataItem.responses = Object.fromEntries(
              languages.map((languageKey) => [
                languageKey,
                [],
              ])
            );
          }
      
          return currentDataItem;
        });
      }

      const reverseTransformedQuestions = reverseTransformQuestionsData(forms[selectedFormIndex].questions);
    return (
        <Modal
            open={openListModal}
        >
            <Grid container sx={subGridStyle}>
                <ThemeProvider theme={basicTheme}>
                <Grid
                  container
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "white",
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}>
                    <Grid item xs={12} sx={{ display: "flex", flexDirection: "row-reverse" }}>
                        <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }} >
                            <CloseRoundedIcon
                                className={classes.closeBtnStyle}
                                onClick={handleCloseByClearIcon}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', paddingBottom: "10px" }}>
                            {forms != null && forms.map((item, index) => (
                                <Typography
                                    variant="body1"
                                    sx={{
                                        display: "inline-block",
                                        height: "38px",
                                        padding: "5px 14px 5px 14px",
                                        borderRadius: "10px",
                                        marginRight: "8px",
                                        backgroundColor: selectedFormIndex === index ? "#2D6A4F" : "#F5F5F5",
                                        color: selectedFormIndex === index ? "#FFFFFF" : "#ACADAD",
                                        cursor: "pointer",
                                        textAlign: 'center',
                                        whiteSpace: 'normal',
                                    }}
                                    key={index}
                                    onClick={() => {
                                        setSelectedFormIndex(index); // Update selectedFormIndex directly
                                    }}
                                >
                                    {item.name}
                                </Typography>
                            ))}
                        </div>
                    </Grid>

                    <Grid item xs={12} marginTop={1.5}>
                        <Typography variant="h5" sx={{ color: "#78828C", fontWeight: "400" }}>
                            Questions
                        </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    spacing={2}
                    xs={12}
                    sx={{
                      paddingLeft: 2,
                      width: "90%",
                    }}
                  >
                    {selectedFormIndex !== null && (
                        <>
                            {reverseTransformedQuestions != null && reverseTransformedQuestions.map((item, index) => (
                                <EditFormCard
                                    index={index}
                                    view={true}
                                    question={item}
                                    key={`${selectedFormIndex}-${index}`}
                                />
                            ))}
                        </>
                    )}
                  </Grid>
                </ThemeProvider>
            </Grid >
        </Modal >
    )
}
