import React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
const StyledGridPopOver = styled(Grid)({
  display: "flex",
  width: "100px",
  marginLeft:10
});

const useStyles = makeStyles((theme) => ({
  editIcon: {
    color: "#2D6A4F",
    cursor: "pointer",
  },
  deleteIcon: {
    color: "#AAC1B0",
    cursor: "pointer",
  },
}));
export default function EditDelete({setopenEditModal=()=>{},setOpenDeleteModal=()=>{},data={},
setEditData = ()=>{},
setDeleteData = ()=>{},disabled=false}) {
  const classes = useStyles();
  return (
    <StyledGridPopOver container>
      <Grid item xs={6}>
        <EditRoundedIcon
          className={classes.editIcon}
          onClick={() => {
            if (!disabled) {
              setopenEditModal(true);
              setEditData(data);
            }
          }}
          style={{ pointerEvents: disabled ? 'none' : 'auto', color: disabled && "#AAAAAA" }}
        />
      </Grid>
      <Grid item xs={6}>
        <DeleteRoundedIcon
          className={classes.deleteIcon}
          onClick={() => {
            if (!disabled) {
              setOpenDeleteModal(true);
              setDeleteData(data);
            }
          }}
          style={{ pointerEvents: disabled ? 'none' : 'auto', color: disabled && "#AAAAAA" }}
        />
      </Grid>
    </StyledGridPopOver>
  );
}
