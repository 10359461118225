import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { Grid, Box, Typography, ThemeProvider } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import TopFilterBar from "../../components/reusableComponents/TopFilterBar";
import DesktopTable from "../../components/reusableComponents/DesktopTable";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import LinearProgress from "@mui/material/LinearProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import StackTable from "../../components/reusableComponents/StackTable";
import PartnerFilterPopOver from "../../components/projects/partnerFilterPopOver";
import SnackBars from "../../components/reusableComponents/SnackBar";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AddProjectModal from "../../components/projects/addProjectModal";
import FormListModal from "../../components/projects/formListModal";
import DeleteProject from "../../components/projects/deleteProjectModal";
import { getProjectData } from "./projectsAPIs";
import EditProjectModal from "../../components/projects/editProjectModal";
import SuccessErrorDialogBox from "../../components/reusableComponents/SuccessErrorDialogBox";
import AlertDialog from "../../components/reusableComponents/AlertDialog";

const MainGrid = styled(Grid)({
  flex: "1",
  backgroundColor: "white",
  // [theme.breakpoints.down("sm")]: {
  //   marginTop: "60px",
  // },
  marginTop: "80px",
});

const initialHeadCells = [
  {
    key: "action",
    label: "Actions",
    type: "editDelete",
    sort: false,
    sticky: true,
  },
  {
    key: "name",
    label: "Project",
    type: "text",
    sort: true,
    capital: true
  },
  {
    key: "forms",
    label: "Forms",
    type: "forms",
  },
  {
    key: "start_date",
    label: "Start Date",
    type: "date",
    sort: true,
  },
  {
    key: "end_date",
    label: "End Date",
    type: "date",
    sort: true,
  },
//   {
//     key: "is_active",
//     label: "Status",
//     type: "boolean",
//     sort: true,
//   },
  {
    key: "partner",
    label: "Partner",
    type: "text",
    capital:true
  },
];

export default function Projects({ }) {
  const dispatch = useDispatch();
  const csvLink = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const rowsPerPage = 10;
  const [openAddModal, setopenAddModal] = React.useState(false);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = React.useState(0);
  const [sortingType, setSortingType] = useState("desc");
  const [sortBy, setSortBy] = useState("created_at");
  const [applyFilter, setApplyFilter] = useState(false);
  const [searchBarData, setSearchBarData] = useState("");
  const [searchBarText, setSeachBarText] = useState("");
  const [exportData, setExportData] = useState("");
  const [exportedFileName, setExportedFileName] = useState("");
  const [projectSnackBarType, setProjectSnackBarType] = useState("success");
  const [projectSnackBarMessage, setProjectSnackBarMessage] = useState("");
  const [showProjectSnackBar, setProjectShowSnackBar] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setopenEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState([]);
  const [apiCall, setApiCall] = useState(false);
  const [openListModal, setOpenListModal] = React.useState(false);
  const [listData, setListData] = React.useState([])
  const [openFilterPopOver, setOpenFilterPopOver] = React.useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState("")
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [selectedProtocols, setSelectedProtocols] = useState([]);
  const [clearFilter, setClearFilter] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [projectDataCount, setProjectDataCount] = useState(-1);
  const [formIdList, setFormIdList] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSuccessBox, setOpenSuccessBox] = useState(false);
  const [openSuccessBoxType,setOpenSuccessBoxType] = useState("success");
  const [successMessage, setSuccessMessage] = useState("");

  const handleShowFilterPopOver = (event) => {
    setOpenFilterPopOver(event.currentTarget);
  };

  const handleCloseFilterPopOver = () => {
    setOpenFilterPopOver(null);
    setApplyFilter(false);
    setClearFilter(false);
  };

  const drawerWidth = useSelector(
    (state) => state.appBarSliceReducer.drawerWidth
  );

  useEffect(() => {
    if (clearFilter) {
      setPage(0);
      setOffset(0);
    }
  }, [clearFilter]);

  useEffect(() => {
    setPage(0);
    setOffset(0);
  }, [searchBarData]);

  useEffect(() => {
    if (applyFilter) {
      handleCloseFilterPopOver();
      setPage(0);
      setOffset(0);
    }
  }, [applyFilter]);

  useEffect(() => {
    setApiCall(true);
  }, [
    searchBarData,
    offset,
    sortingType,
    sortBy,
    filters
  ]);

  useEffect(() => {
    if (apiCall || reload) {
      setLoading(true)
      const params = {
        search: searchBarData,
        offset: offset,
        limit: rowsPerPage,
        sort_key: sortBy,
        sort_by: sortingType,
      }
      const fetchData = async () => {
        try {
          const response = await getProjectData(params)
          if (response != null && response != undefined &&
            response.data !== null && response.data !== undefined &&
            response.data.data !== null && response.data.data !== undefined
          ) {
            setProjectDataCount(response.data.count)
            setProjectData(response.data.data);
          } else {
            setProjectShowSnackBar(true);
            setProjectSnackBarType("error");
            setProjectSnackBarMessage("Something went wrong on sever!");
          }
        } catch (error) {
          setProjectShowSnackBar(true);
          setProjectSnackBarType("error");
          setProjectSnackBarMessage(error.message);
          console.error("API Error:", error);
        } finally {
          setLoading(false);
          setApiCall(false);
          setReload(false);
        }
      };
      fetchData();
    }
  }, [dispatch, apiCall, reload,]);

  useEffect(() => {
    if (projectSnackBarMessage !== "" && projectSnackBarMessage !== undefined)
      setProjectShowSnackBar(true);
  }, [projectSnackBarMessage]);

  return (
    <MainGrid
      container
      id="main"
      sx={{ marginLeft: drawerWidth + "px", width: "auto" }}
      spacing={3}
    >
      <ThemeProvider theme={basicTheme}>
        <Grid item xs={12}>
          <TopFilterBar
            addtext="Add Project"
            setAddOpen={setopenAddModal}
            searchBarData={searchBarData}
            setSearchBarData={setSearchBarData}
            searchBarText={searchBarText}
            setSeachBarText={setSeachBarText}
            handleShowFilterPopOver={handleShowFilterPopOver}
            setExportData={setExportData}
            searchBy="Search By Project Name"
            loading={loading}
            filterSelected={selectedStates.length !== 0 || selectedPartners.length !== 0 || selectedProtocols.length !== 0}
          />
          {openFilterPopOver && (
            <PartnerFilterPopOver
              handleClose={handleCloseFilterPopOver}
              openFilterPopOver={openFilterPopOver}
              setApplyFilter={setApplyFilter}
              filters={filters}
              setFilters={setFilters}
              selectedStates={selectedStates}
              setSelectedStates={setSelectedStates}
              selectedPartners={selectedPartners}
              setSelectedPartners={setSelectedPartners}
              selectedProtocols={selectedProtocols}
              setSelectedProtocols={setSelectedProtocols}
              clearFilter={clearFilter}
              setClearFilter={setClearFilter}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {loading && (
            <Box>
              <LinearProgress color="success" />
            </Box>
          )}
          {isSmallScreen ? (
            <StackTable
              key={projectData}
              headCells={initialHeadCells}
              tableResponse={projectData}
              totalCount={projectDataCount}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setopenEditModal={setopenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
            />
          ) : (!loading && (
            <DesktopTable
              key={projectData}
              headCells={initialHeadCells}
              tableResponse={projectData}
              totalCount={projectDataCount}
              setOffset={setOffset}
              rowsPerPage={rowsPerPage}
              setSortingType={setSortingType}
              sortingType={sortingType}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setopenEditModal={setopenEditModal}
              setEditData={setEditData}
              setOpenDeleteModal={setOpenDeleteModal}
              setDeleteData={setDeleteData}
              setPage={setPage}
              page={page}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
              setOpenListModal={setOpenListModal}
              setListData={setListData}
            />
          )
          )}
        </Grid>
        {openListModal && (
          <FormListModal
            openListModal={openListModal}
            handleClose={() => setOpenListModal(false)}
            forms={listData}
          />
        )}
        {openEditModal && (
          <EditProjectModal
            openEditModal={openEditModal}
            handleClose={() => setopenEditModal(false)}
            data={editData}
            setProjectSnackBarMessage={setProjectSnackBarMessage}
            setProjectSnackBarType={setProjectSnackBarType}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
            setOpenSuccessBoxType={setOpenSuccessBoxType}
          />
        )}
        {openAddModal && (
          <AddProjectModal
            openAddModal={openAddModal}
            handleClose={() => setopenAddModal(false)}
            setProjectSnackBarMessage={setProjectSnackBarMessage}
            setProjectSnackBarType={setProjectSnackBarType}
            setReload={setReload}
            setOpenSuccessBox={setOpenSuccessBox}
            setSuccessMessage={setSuccessMessage}
            setOpenSuccessBoxType={setOpenSuccessBoxType}
          />
        )}
        {openSuccessBox && 
        <SuccessErrorDialogBox open={openSuccessBox}
        handleClose={() => setOpenSuccessBox(false)}
        text={successMessage}
        type={openSuccessBoxType}
        />
        }
        {openAlertModal && (
          <AlertDialog
            handleClose={() => setOpenAlertModal(false)}
            open={openAlertModal}
            heading="Can't delete this Project"
            text={openAlertMessage}
          />
        )}
        {openDeleteModal && (
          <DeleteProject
            handleClose={() => setOpenDeleteModal(false)}
            open={openDeleteModal}
            deleteData={deleteData}
            setSnackBarMessage={setProjectSnackBarMessage}
            setSnackBarType={setProjectSnackBarType}
            setReload={setReload}
            setOpenAlertModal={setOpenAlertModal}
            setOpenAlertMessage={setOpenAlertMessage}
          />
        )}
        {/* <CSVLink
          data={exportedData}
          filename={exportedFileName}
          className="hidden"
          ref={csvLink}
          target="_blank"
          separator={";"}
        /> */}
        {showProjectSnackBar && (
          <SnackBars
            open={showProjectSnackBar}
            handleClose={() => {setProjectShowSnackBar(false);setProjectSnackBarMessage("");}}
            severity={projectSnackBarType}
            message={projectSnackBarMessage}
            autoHideDuration={6000}
            vertical="bottom"
            horizontal="center"
          />
        )}
        {loading && (
          <Backdrop
            sx={{
              color: "#fff",
              position: "absolute",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </ThemeProvider>
    </MainGrid>
  );
}
