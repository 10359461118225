import React, { useState, useEffect } from "react";
import { styled, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import SnackBars from "../reusableComponents/SnackBar";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Box,
  Button,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MultipleSelectCheckmarks from "../rolesAndPermissions/MultipleSelectCheckmarks";
import _without from "lodash/without";
import EditDelete from "../reusableComponents/EditDelete";
import dayjs from "dayjs";
const Item = styled(Paper)(({ theme, index }) => ({
  padding: theme.spacing(1),
}));

export default function StackTable({
  headCells,
  tableResponse = [],
  totalCount,
  setOpenLocationSubModal = () => {},
  showPagination = true,
  rowsPerPage = 10,
  setOffset = () => {},
  setSortingType = () => {},
  sortingType,
  setSortBy = () => {},
  sortBy,
  setopenEditModal = () => {},
  setEditData = () => {},
  setOpenDeleteModal = () => {},
  setDeleteData = () => {},
  setPage = () => {},
  page = 0,
  handleEditPermission = () => {},
  setOpenRolesConfirmationBox = {},
  setPermissionViaAlert,
  discardAlert,
  setDiscardAlert,
  openEditModal,
  openDeleteModal,
  setOpenListModal=() => {},
  setListData=() => {}
}) {
  const [permission, setPermission] = React.useState(tableResponse);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [showSnackBarMessage, setShowSnackBarMessage] = useState("");

  useEffect(() => {
    if (totalCount === -1 || totalCount === 0) {
      setShowSnackBar(true);
      setShowSnackBarMessage("No Data Found! Try changing filters");
    } else {
      setShowSnackBar(false);
      setShowSnackBarMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortRequest = (cellId) => {
    const shouldSortDesc = sortBy === cellId && sortingType === "asc";
    setSortingType(shouldSortDesc ? "desc" : "asc");
    // setPage(0);
    setSortBy(cellId);
  };

  useEffect(() => {
    setOffset(page * rowsPerPage);
  }, [page]);

  return (
    <React.Fragment>
      {tableResponse.map((row, index) => {
        return (
          <Box
           key = {index}
            sx={{
              borderBlockEnd: "1px solid #ccc",
              px: 2,
              marginLeft:3,
              width:"300px"
            }}
          >
            <Grid>
              <Item
                key = {index}
                style={{
                  backgroundColor: index % 2 ? "#FFFFFF" : "#FAF9F6",
                }}
              >
                <span style={{ fontWeight: "bold" }}>SL No.</span>
                <span> {index + 1}</span>
              </Item>

              {headCells.map((headCell,ind) => {
                return (
                  <Item
                    key = {ind}
                    style={{
                      backgroundColor: index % 2 ? "#FFFFFF" : "#FAF9F6",
                    }}
                  >
                    {headCell.label!=="Actions" && 
                    <span style={{ fontWeight: "bold" }}>
                      {headCell.label}:
                    </span>
                    }
                    <span>
                      {(headCell.type === "question" || headCell.type == 'forms') && row[headCell.key] !== null && row[headCell.key] !== undefined &&
                              row[headCell.key].length > 0 ? (
                              <p style={{ color: "#52B788", cursor: 'pointer' }}>
                                <span
                                  onClick={() => {
                                    setOpenListModal(true)
                                    setListData(row[headCell.key])
                                  }}
                                >
                                  {headCell.type === "question" ? (
                                    `Questions(${row[headCell.key].length})`
                                  ) : (
                                    `Forms(${row[headCell.key].length})`
                                  )}
                                </span>
                              </p>
                            ): headCell.type === "date" ? (
                              <p>
                               {row[headCell.key] === undefined ? row[headCell.key]:dayjs(row[headCell.key]).format('D MMMM, YYYY')}
                              </p>
                            ) :headCell.type === "checkbox" ? (
                        <Checkbox
                          disabled
                          checked={row[headCell.key] ? true : false}
                          sx={{
                            color: "#2D6A4F",
                            "&.Mui-checked": { color: "#2D6A4F" },
                          }}
                        />
                      ) : headCell.type === "editDelete" ? (
                        <EditDelete
                          setopenEditModal={setopenEditModal}
                          setOpenDeleteModal={setOpenDeleteModal}
                          data={row}
                          setEditData={setEditData}
                          setDeleteData={setDeleteData}
                        />
                      ) : headCell.type === "multiSelect" ? (
                        <>
                          {MultipleSelectCheckmarks(
                            permission,
                            // handleChangePermission,
                            handleEditPermission,
                            index,
                            headCell.key,
                            tableResponse[index].modules,
                            setOpenRolesConfirmationBox,
                            setPermissionViaAlert,
                            discardAlert,
                            setDiscardAlert
                          )}
                        </>
                      ) : headCell.type === "location" ? (
                        row[headCell.key] !== null &&
                        row[headCell.key] !== undefined &&
                        row[headCell.key].length > 0 &&
                        row[headCell.key].map((state_wise, index) => {
                          return (
                            <p key={index}>
                              {state_wise.state.name}{" "}
                              <span
                                onClick={() => {
                                  setOpenLocationSubModal(true);
                                  setEditData(row);
                                }}
                                style={{
                                  color: "#52B788",
                                  cursor: "pointer",
                                }}
                              >
                                ( {state_wise.locations.length} )
                              </span>
                            </p>
                          );
                        })
                      ) : headCell.type === "array_objects" ? (
                        row["role"] !== "Government" &&
                        row[headCell.key].data.length > 0 &&
                        row[headCell.key].data.map((arr, index) => {
                          return <p key={index}>{arr.name}</p>;
                        })
                      ) : headCell.type === "pesticide" ? (
                        <p>
                          <span>{row.code}</span> <span>{row.title}</span>
                        </p>
                      ) : headCell.type === "partnerPesticide" ? (
                        row.pesticides.map((list_data, ind) => {
                          return <p key={ind}>{list_data} </p>;
                        })
                      ) : headCell.type === "location" ? (
                        row[headCell.key].length > 0 &&
                        row[headCell.key].map((state_wise, index) => {
                          return (
                            <p key={index}>
                              {state_wise.state.name}{" "}
                              <span
                                onClick={() => {
                                  setOpenLocationSubModal(true);
                                  setEditData(row);
                                }}
                                style={{
                                  color: "#52B788",
                                  cursor: "pointer",
                                }}
                              >
                                ( {state_wise.locations.length} )
                              </span>
                            </p>
                          );
                        })
                      ) : headCell.type === "user" ? (
                        <p>
                          <span>{row[headCell.key].name}</span>
                          <br />
                          <span>{row[headCell.key].email}</span>
                          <br />
                          <span>{row[headCell.key].phone_number}</span>
                        </p>
                      ) : headCell.type === "name_number" ? (
                        <p>
                          <span>{row[headCell.key].name}</span>
                          <br />
                          <span>{row[headCell.key].phone_number}</span>
                        </p>
                      ) : headCell.type === "season" ? (
                        <p>{row[headCell.key].name}</p>
                      ) : headCell.type === "name_number_location" ? (
                        <p>
                          <span>{row.name}</span>&nbsp;
                          <br />
                          <span>{row.phone_number}</span>&nbsp;
                          <br />
                          <span>{row.location}</span>
                        </p>
                      ) : headCell.type === "boolean" ? (
                        row[headCell.key] == true ? (
                          <Typography sx={{ color: "#2D6A4F" }}>
                            Active
                          </Typography>
                        ) : row[headCell.key] == false ? (
                          <Typography sx={{ color: "#EB5451" }}>
                            Inactive
                          </Typography>
                        ) : (
                          ""
                        )
                      ) : row[headCell.key] && row[headCell.key].id ? (
                        row[headCell.key].name
                      ) : (
                        <p>{row[headCell.key]}</p>
                      )}
                    </span>
                  </Item>
                );
              })}
            </Grid>
          </Box>
        );
      })}
    </React.Fragment>
  );
}
