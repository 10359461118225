import React, { useState, useEffect } from "react";

import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  InputAdornment,
  Box,
  Chip,
  Checkbox,
  Button,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ListItemText from "@mui/material/ListItemText";
import axios from "axios";
import {
  hierarchyApi,
  locationByIdAPI,
  districtsApi,
  talukasApi,
  statesApi,
  appUsersAPI,
  partnerDetailsAPI,
  extensionWorkerAPI
} from "../api's";
import { styled } from "@mui/material/styles";
var qs = require("qs");

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomSelect = styled(Select)({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: "#f0f0f0",
  },
});

export const displayDropdown = (text, id, handleChange, menuList,disabled=false,required = false) => {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required = {required}
      sx={{
        marginBottom: 1,
        borderRadius: 2,
        width: "90%",
        backgroundColor: "#F5F5F5",
      }}
    >
      <InputLabel>{text}</InputLabel>
      <Select
        value={id}
        label={text}
        onChange={handleChange}
        sx={{ borderRadius: 2 }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        disabled={menuList[0].id === "" || disabled}
      >
        {menuList.map((menu) => {
          return (
            // <>
            // {
            menu.id ? (
              <MenuItem key={menu.id} value={menu.id}>
                {menu.name}
              </MenuItem>
            ) : menu.code ? (
              <MenuItem key={menu.code} value={menu.code}>
                {menu.name}
              </MenuItem>
            ) : (
              <MenuItem key={menu.name} value={menu.name}>
                {menu.name}
              </MenuItem>
            )
            // }
            // </>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const displayTextField = (
  label,
  is_phone = false,
  handleChange,
  is_disabled,
  index,
  error = false,
  helperText = "",
  required = false,
  value = "",
  number = false
) => {
  return (
    <TextField
      type={number===true ? "tel" :"string"}
      label={label}
      key={index}
      disabled={is_disabled}
      required={required}
      error={error}
      helperText={helperText}
      value={value}
      variant="outlined"
      sx={{ width: "90%", backgroundColor: "#F5F5F5", borderRadius: 2 }}
      inputProps={{
        style: {
          height: "40%",
          borderRadius: 2,
        },
        "aria-label": "Without label",
      }}
      InputProps={{
        startAdornment: is_phone && (
          <InputAdornment position="start">
            <p>+91 | </p>
          </InputAdornment>
        ),
      }}
      onChange={(e) => handleChange(e.target.value, index)}
    />
  );
};

export const displayEditTextField = (
  label,
  is_phone = false,
  handleChange,
  is_disabled,
  index,
  error = false,
  helperText = "",
  required = false,
  value = ""
) => {
  return (
    <TextField
      label={label}
      key={index}
      disabled={is_disabled}
      required={required}
      error={error}
      helperText={helperText}
      value={value}
      variant="outlined"
      sx={{ width: "90%", backgroundColor: "#F5F5F5", borderRadius: 2 }}
      inputProps={{
        style: {
          height: "40%",
          borderRadius: 2,
        },
        "aria-label": "Without label",
      }}
      InputProps={{
        maxLength: 40,
        startAdornment: is_phone && (
          <InputAdornment position="start">
            <p>+91 | </p>
          </InputAdornment>
        ),
      }}
      onChange={(e) => handleChange(e.target.value, index)}
    />
  );
};

export const DisplayMultiSelectDropdown = (
  text,
  selectedValues = [],
  handleChange,
  handleDelete,
  menuList,
  selectedValuesNames = [],
  setSelectedValueNames = null
) => {
  const [openMultiSelect, setOpenMultiSelect] = useState(false);
  return (
    <FormControl
      sx={{
        minWidth: "90%",
        maxWidth: "90%",
        backgroundColor: "#F5F5F5",
        marginLeft: 1,
      }}
    >
      <Select
        open={openMultiSelect}
        onOpen={() => setOpenMultiSelect(true)}
        onClose={() => setOpenMultiSelect(false)}
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={selectedValues}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
        renderValue={(selected) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            {selected.map((value) => (
              <Chip
                key={value}
                label={value}
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                onDelete={(e) => handleDelete(e, value)}
                sx={{
                  margin: 0.2,
                  backgroundColor: "#FFF",
                }}
              />
            ))}
          </Box>
        )}
        sx={{ borderRadius: 2 }}
        MenuProps={MenuProps}
      >
        {menuList.map((menu, idx) => {
          return (
            <MenuItem key={idx} value={menu.code}>
              <Checkbox checked={selectedValues.includes(menu.code)} />
              <ListItemText primary={menu.code} />
            </MenuItem>
          );
        })}
        <Box sx={{ textAlign: "center" }}>
          <Button
            onClick={() => setOpenMultiSelect(false)}
            fullWidth
            variant="outlined"
            sx={{ color: "#2D6A4F", backgroundColor: "#F0F8F1" }}
          >
            Done
          </Button>
        </Box>
      </Select>
    </FormControl>
  );
};

export const displayLocationDropdown = (
  id,
  handleChangeLocation,
  menuList,
  index = 0,
  type = "",
  disable = false
) => {
  const handleChange = (e) => {
    handleChangeLocation(e.target.value, index, type);
  };
  return (
    <FormControl
      fullWidth
      variant="outlined"
      sx={{
        marginBottom: 1,
        borderRadius: 2,
        width: "90%",
        backgroundColor: "#F5F5F5",
      }}
    >
      <Select
        value={id}
        onChange={handleChange}
        sx={{ borderRadius: 2 }}
        inputProps={{ "aria-label": "Without label" }}
        disabled={menuList.length == 0 || disable}
      >
        {menuList.map((menu) => {
          return (
            <MenuItem key={menu.id} value={menu.id}>
              {menu.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const displayBasicDropdown = (
  text,
  value,
  handleChange,
  menuList,
  error = false,
  required = false,
  disable = false,
) => {
  return (
    <FormControl
      fullWidth
      variant="outlined"
      required={required}
      sx={{
        marginBottom: 1,
        borderRadius: 2,
        width: "90%",
        backgroundColor: "#F5F5F5",
      }}
      error={error}
    >
      <InputLabel>{text}</InputLabel>
      <CustomSelect
        value={value}
        label={text}
        onChange={handleChange}
        sx={{ borderRadius: 2 }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
        placeholder={text}
        disabled={
          (menuList[0] !== undefined &&
            menuList[0].id !== undefined &&
            menuList[0].id === "") ||
          menuList.length === 0 ||
          disable
        }
      >
        {menuList.map((menu) => {
          return menu.id ? (
            <MenuItem key={menu.id} value={menu.id}>
              {menu.name}
            </MenuItem>
          ) : (
            <MenuItem key={menu} value={menu}>
              {menu}
            </MenuItem>
          );
        })}
      </CustomSelect>
    </FormControl>
  );
};

export const getDistrictList = async (location_ids) => {
  try {
    const response = await axios.get(hierarchyApi, {
      params: {
        type: "state",
        location_ids: [location_ids],
      },
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "repear" }),
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getTalukaList = async (location_ids) => {
  try {
    const response = await axios.get(hierarchyApi, {
      params: {
        type: "district",
        location_ids: [location_ids],
      },
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "repear" }),
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getVillageList = async (location_ids) => {
  try {
    const response = await axios.get(hierarchyApi, {
      params: {
        type: "taluka",
        location_ids: [location_ids],
        is_active: true,
      },
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "repear" }),
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getLocationById = async (location_ids) => {
  try {
    const data = {
      id: location_ids,
      partial: true,
    };

    const serializedData = qs.stringify(data, { arrayFormat: 'repeat' });

    const response = await axios.post(locationByIdAPI, serializedData, {
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const updateArray = (
  array,
  type,
  newValue,
  remove = false,
  setArray
) => {
  const newArray = [...array];
  var objIndex = newArray.findIndex((obj) => obj.type == type);
  if (remove) {
    setArray((rem) => rem.filter((_, ind) => ind !== objIndex));
    return;
  }

  newArray[objIndex].value = newValue;
  return newArray;
};

export const removeDuplicates = (arr) => {
  return arr.filter((item, index) => arr.indexOf(item) === index);
};

export const getAllStateList = async (data) => {
  try {
    const response = await axios.get(statesApi, {
      params: data,
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "repear" }),
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllDistrictList = async (data) => {
  try {
    const response = await axios.get(districtsApi, {
      params: data,
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "repear" }),
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllTalukaList = async (data) => {
  try {
    const response = await axios.get(talukasApi, {
      params: data,
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "repear" }),
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const searchType = (nameKey, myArray) => {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i].type === nameKey) {
      return true;
    }
  }
};

export const getHeaders = () => {
  const headers = {
    Authorization: window.localStorage.getItem("loggedInUser")
      ? JSON.parse(window.localStorage.getItem("loggedInUser")).token
      : "",
  };

  return headers;
};

export const getEwList = async (data) =>{
  try {
    const headers = getHeaders();
    const response = await axios.get(extensionWorkerAPI, {
      params: data,
      headers: headers,
      paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: "repear" }),
      },
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export const getEachPartnerData = async (id) =>{
  try {
    const headers = getHeaders();
    const response = await axios.get(partnerDetailsAPI+id, {headers: headers});
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function commonApiCall({ method, url, data, params,headers=null }) {
  try {
    if(headers===null)
    headers = getHeaders();
    const response = await axios({
      method,
      url: url,
      data,
      params,
      headers,
      paramsSerializer: {
        serialize: (params) =>
          qs.stringify(params, { arrayFormat: "repeat" }),
      },
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      window.location.reload();
      localStorage.clear();
    }
    throw error;
  }
}

export function capitaliseFirstLetter(str) {
  if  (!str || str==="" || str == undefined){
    return str
  }
  if (typeof str === "string") {
    let words = str.split(" ");
    for (let i = 0; i < words.length; i++) {
      if (words[i].length > 0) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
      }
    }
    let newStr = words.join(" ");
    return newStr;
  } else {
    return str;
  }
}


