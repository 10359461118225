import React from "react";
import { Box, Checkbox, Divider, Grid, IconButton, Popover, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const StyledGridPopOver = styled(Grid)({
  display: "flex",
  width: "100px",
  marginLeft: 10,
});

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    background: "#AAC1B0",
    width: 'fit-content',
    padding: '3px 4px',
    borderRadius: "50%",
    '&:hover': {
      background: '#919E94',
    },
  },
  dotIcon: {
    color: "#FFF",
    cursor: "pointer",
    fontSize: "16px"
  },
  editIcon: {
    color: "#AAC1B0",
    cursor: "pointer",
    fontSize: "16px"
  },
  deleteIcon: {
    color: "#AAC1B0",
    cursor: "pointer",
    fontSize: "16px"
  },
}));

export default function EditMultipleDelete({
  data={},
  disabled=false,
  deleteMultipleUserData,
  setopenEditModal=()=>{},
  setOpenDeleteModal=()=>{},
  setEditData=()=>{},
  setDeleteData=()=>{},
  setDeleteMultipleUserData=()=>{},
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDeleteButtonClicked, setIsDeleteButtonClicked] = React.useState(false);
  const [checked, setChecked] = React.useState(false)
  const classes = useStyles();
  
  const handleShowDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setIsDeleteButtonClicked(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const enableMultipleDelete = (event) => {
    setDeleteMultipleUserData(prev => ({
      ...prev,
      isOpen: true,
      users: {
        ...prev.users,
        [data?.id]: {
          ...prev.users[data?.id],
          isSelected: event.target.checked
        }
      }
    }));
  };

  const initialDialgContent = <>
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: '6rem', paddingBottom: '1rem' }}>
      <Typography sx={{ fontSixe: "16px", color: "#2D6A4F", fontWeight: "600" }}>
        Edit
      </Typography>
      <EditRoundedIcon
        className={classes.editIcon}
        onClick={() => {
          if (!disabled) {
            setopenEditModal(true);
            setEditData(data);
          }
        }}
        style={{ pointerEvents: disabled ? 'none' : 'auto', color: disabled && "#AAAAAA" }}
      />
    </Box>
    <Divider />
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: '6rem', paddingTop: '1rem' }}>
      <Typography sx={{ fontSixe: "16px", color: "#2D6A4F", fontWeight: "600" }}>
        Delete
      </Typography>
      <DeleteRoundedIcon
        className={classes.deleteIcon}
        onClick={() => setIsDeleteButtonClicked(true)}
          // {
            // setopenEditModal(true);
            // setEditData(data);
        // }}
        style={{ pointerEvents: disabled ? 'none' : 'auto', color: disabled && "#AAAAAA" }}
      />
    </Box>
  </>

  const checkboxComponent = <>
    <Checkbox
        // disabled
      checked={deleteMultipleUserData?.users[data.id]?.isSelected}
      onChange={enableMultipleDelete}
      sx={{
        color: "#c5d6ca",
        "&.Mui-checked": { color: "#2D6A4F" },
        "&hover": { color: "#2D6A4F" }
      }}
    />
  </>

  const deleteOptionsContent = <>
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: '6rem', paddingBottom: '1rem' }}>
      <Typography sx={{ fontSixe: "16px", color: "#2D6A4F", fontWeight: "600" }}>
        Delete this
      </Typography>
      <DeleteRoundedIcon
        className={classes.deleteIcon}
        onClick={() => {
          setOpenDeleteModal(true);
          setDeleteData(data);
        }}
        style={{ pointerEvents: disabled ? 'none' : 'auto', color: disabled && "#AAAAAA", marginRight: "10px" }}
      />
    </Box>
    <Divider />
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", columnGap: '6rem', paddingTop: '0.5rem' }}>
      <Typography sx={{ fontSixe: "16px", color: "#2D6A4F", fontWeight: "600" }}>
        Select more to delete
      </Typography>
      {checkboxComponent}
    </Box>
  </>

  return (
    <StyledGridPopOver container>
      <Grid item xs={4} />
      <Grid item xs={4} sx={{ position: 'relative' }}>
        {deleteMultipleUserData?.isOpen ? checkboxComponent : <>
        <Box className={classes.iconWrapper} sx={{ background: anchorEl ? "#919E94" : "#AAC1B0" }} onClick={handleShowDropdown}>
          <MoreHorizIcon className={classes.dotIcon} />
        </Box>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleCloseDropdown}
          sx={{
            "& .MuiPopover-paper": {
              padding: 2.5,
              marginTop: "8px",
              borderRadius: "20px",
              border: "0.5px solid #74C69D"
            },
          }}
        >
          <Box>
            {isDeleteButtonClicked ? deleteOptionsContent : initialDialgContent}
          </Box>
        </Popover>
        </>}
      </Grid>
      <Grid item xs={4} />
    </StyledGridPopOver>
  );
}
