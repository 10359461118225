import React, { useState } from "react";
import { Grid, Typography, Modal, ThemeProvider, Box } from "@mui/material";
import { basicTheme } from "../../themes/customTheme";
import { makeStyles } from "@mui/styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FormCard from "../formManagement/formCard";
import OptionalDropDown from "../reusableComponents/OptionalDropDown";
import EditFormCard from "./editFormCard";

const useStyles = makeStyles(() => ({
  closeBtnStyle: {
    zoom: "2.2",
    alignItems: "right",
    color: "#444444",
    cursor: "pointer",
    "&:hover": { opacity: 0.7 },
    maxHeight: "1rem",
    maxWidth: "1rem",
  },
}));
const subGridStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  paddingLeft: 6,
  paddingRight: 4,
  paddingBottom: 4,
  borderRadius: "1.5rem",
  width: "65%",
  maxHeight: "80%",
  overflow: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
};

export default function QuestionsListModal({
  openListModal,
  handleClose = () => {},
  questions,
  languages = [],
}) {
  const classes = useStyles();

  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const handleCloseByClearIcon = () => {
    handleClose();
  };

  const handleChangeLanguage = (event) => {
    setSelectedLanguage(event.target.value);
  };

  function reverseTransformQuestionsData(formattedData) {
    const languages = ["english", "hindi", "gujarati", "marathi"];
    return formattedData.map((item) => {
      const currentDataItem = {
        response_type: item.response_type,
      };
  
      languages.forEach((language) => {
        if (item[language]) {
          currentDataItem[language] = item[language];
        }
      });
  
      if (item.model_metadata) {
        const { question_type, units, values } = item.model_metadata;
  
        if (question_type) {
          currentDataItem.question_type = question_type;
        }
  
        if (units) {
          const unitList = Object.fromEntries(
            languages.map((languageKey) => [
              languageKey,
              units[languageKey] || [],
            ])
          );
          currentDataItem.units = unitList;
        }
  
        if (values) {
          if (question_type === "MCQ") {
  
            const dict = {};
            languages.forEach(language => {
              if (values[language]) {
                dict[language] = {};
  
                values[language].forEach(item => {
                  dict[language][item.master] = item.slave;
                });
              } else {
                dict[language] = {};
              }
            });
            currentDataItem.dict = dict;
  
            const mcqresponses = {};
            languages.forEach((language) => {
              if (values[language]) {
                mcqresponses[language] = values[language].map((item) => item.master);
              } else {
                mcqresponses[language] = []
              }
            });
            currentDataItem.responses = mcqresponses;
  
          }
        }
      }
  
      if (item.responses) {
        currentDataItem.responses = item.responses;
      } else if (!(item.response_type === "audio" &&
        item.model_metadata &&
        item.model_metadata.question_type &&
        item.model_metadata.question_type === "MCQ")) {
        currentDataItem.responses = Object.fromEntries(
          languages.map((languageKey) => [
            languageKey,
            [],
          ])
        );
      }
  
      return currentDataItem;
    });
  }
  

  const reverseTransformedQuestions = reverseTransformQuestionsData(questions);

  return (
    <Modal open={openListModal}>
      <Grid container sx={subGridStyle} spacing={1}>
        <ThemeProvider theme={basicTheme}>
          <Grid
            container
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              paddingTop: 3,
              alignItems: "center",
              paddingBottom: 1,
            }}
          >
            <Grid item xs={11}></Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "flex-end", paddingBottom: 1 }}
            >
              <CloseRoundedIcon
                className={classes.closeBtnStyle}
                onClick={handleCloseByClearIcon}
              />
            </Grid>
            <Grid item xs={8} marginY={1.5}>
              <Typography
                variant="h4"
                sx={{ color: "#444444", fontWeight: "500" }}
              >
                Questions
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Box
                display="flex"
                alignItems="center"
                bgcolor="#F5F5F5"
                p={2}
                borderRadius={20}
                width="250px"
                height="40px"
                padding="12px 16px"
              >
                <Typography
                  sx={{ fontFamily: "Inter", color: "#78828C", marginRight: 2 }}
                >
                  Translations
                </Typography>
                <OptionalDropDown
                  text={"Translations"}
                  id={selectedLanguage}
                  handleChange={handleChangeLanguage}
                  menuList={languages}
                  borderRadius="24px"
                  height="100%"
                  width="50%"
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            xs={12}
            sx={{
              width: "90%",
            }}
          >
            {reverseTransformedQuestions != null &&
              reverseTransformedQuestions.map((item, index) => (
                <EditFormCard
                  index={index}
                  key={index}
                  view={true}
                  question={item}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                />
              ))}
          </Grid>
        </ThemeProvider>
      </Grid>
    </Modal>
  );
}
